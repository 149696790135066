html,
body {
    height: 100%;
}

.jumbotron {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
}

.container-title {
    margin-top: 40px;
}

.container-no-text {
    height: 35vh;
}

.jumbotron-bg-1 {
    background-image: url(image/bg/bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.jumbotron-bg-2 {
    background-image: url(image/bg/bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 6px;
}

.jumbotron-bg-3 {
    background-image: url(image/bg/bg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 6px;
}

.jumbotron-bg-4 {
    background-image: url(image/bg/bg4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 6px;
}

.jumbotron-bg-5 {
    background-image: url(image/bg/bg5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 6px;
}

.staff-avatar,
.staff-desc {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.staff-title {
    margin-bottom: 2em;
}

h3 {
    margin-bottom: 1em;
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    text-align: right;
    /* border-right: 5px solid #eee; */
    border-left: 0;
}